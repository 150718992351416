/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/zone1.glb");
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="motorway_sign009"
          position={[2.377, 0.817, 4.202]}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.202}
        >
          <mesh
            name="Cube051"
            castShadow
            receiveShadow
            geometry={nodes.Cube051.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cube051_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube051_1.geometry}
            material={materials["motorway-sign"]}
          />
        </group>
        <group
          name="base_zone_1"
          position={[0, -1.272, 0]}
          scale={[5.649, 1.271, 5.649]}
        >
          <mesh
            name="Cube"
            castShadow
            receiveShadow
            geometry={nodes.Cube.geometry}
            material={materials.road}
          />
          <mesh
            name="Cube_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube_1.geometry}
            material={materials.Mat}
          />
        </group>
        <group
          name="road_straight_sections001"
          position={[-3.665, 0.01, -5.542]}
          scale={[0.217, 0.087, 0.087]}
        >
          <mesh
            name="Plane006"
            castShadow
            receiveShadow
            geometry={nodes.Plane006.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Plane006_1"
            castShadow
            receiveShadow
            geometry={nodes.Plane006_1.geometry}
            material={materials["road markings"]}
          />
        </group>
        <group name="road_surface_curved" position={[0, 0, -0.037]}>
          <mesh
            name="Plane_1"
            castShadow
            receiveShadow
            geometry={nodes.Plane_1.geometry}
            material={materials["road markings"]}
          />
          <mesh
            name="Plane_2"
            castShadow
            receiveShadow
            geometry={nodes.Plane_2.geometry}
            material={materials.Mat}
          />
        </group>
        <mesh
          name="Cylinder002"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder002.geometry}
          material={nodes.Cylinder002.material}
          position={[-0.504, -0.001, 3.078]}
          rotation={[0, 0.522, 0]}
          scale={[0.262, 0.08, 0.262]}
        />
        <mesh
          name="store_carpark"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark.geometry}
          material={materials.Mat}
          position={[-4.079, 0.008, 2.206]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.76, 0.76, 0.982]}
        />
        <mesh
          name="store_carpark001"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark001.geometry}
          material={materials.Mat}
          position={[-1.771, 0.008, -3.567]}
          scale={[0.76, 0.76, 0.982]}
        />
        <mesh
          name="store_carpark002"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark002.geometry}
          material={materials.Mat}
          position={[2.058, 0.008, -0.616]}
          scale={[0.76, 0.76, 0.982]}
        />
        <mesh
          name="store_carpark003"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark003.geometry}
          material={materials.Mat}
          position={[-3.22, 0.008, -1.648]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.76, 0.76, 0.982]}
        />
        <mesh
          name="store_carpark004"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark004.geometry}
          material={materials.Mat}
          position={[3.576, 0.008, -4.154]}
          scale={[0.76, 0.76, 0.982]}
        />
        <mesh
          name="store_carpark006"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark006.geometry}
          material={materials.Mat}
          position={[-4.856, 0.008, -3.815]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.76, 0.76, 0.982]}
        />
        <mesh
          name="store_carpark007"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark007.geometry}
          material={materials.Mat}
          position={[3.627, 0.008, 1.907]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.76, 0.76, 0.982]}
        />
        <mesh
          name="store_carpark010"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark010.geometry}
          material={materials.Mat}
          position={[3.627, 0.008, 2.83]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.76, 0.76, 0.982]}
        />
        <mesh
          name="store_carpark011"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark011.geometry}
          material={materials.Mat}
          position={[1.424, 0.008, 2.316]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.76, 0.76, 0.982]}
        />
        <mesh
          name="store_carpark013"
          castShadow
          receiveShadow
          geometry={nodes.store_carpark013.geometry}
          material={materials.Mat}
          position={[-0.718, 0.008, -0.629]}
          scale={[0.76, 0.76, 0.982]}
        />
        <group
          name="billboard"
          position={[0.684, 1.722, -3.656]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.133}
        >
          <mesh
            name="billboard_1"
            castShadow
            receiveShadow
            geometry={nodes.billboard_1.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            name="billboard_1_1"
            castShadow
            receiveShadow
            geometry={nodes.billboard_1_1.geometry}
            material={materials.Mat}
          />
          <mesh
            name="billboard_1_2"
            castShadow
            receiveShadow
            geometry={nodes.billboard_1_2.geometry}
            material={materials["billboard-1"]}
          />
        </group>
        <mesh
          name="Building_1"
          castShadow
          receiveShadow
          geometry={nodes.Building_1.geometry}
          material={materials.Mat}
          position={[1, -0.004, 1.957]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.04}
        />
        <mesh
          name="Building_1001"
          castShadow
          receiveShadow
          geometry={nodes.Building_1001.geometry}
          material={materials.Mat}
          position={[-1.424, -0.004, -4.104]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.04}
        />
        <mesh
          name="Building_1002"
          castShadow
          receiveShadow
          geometry={nodes.Building_1002.geometry}
          material={materials.Mat}
          position={[3.213, -0.004, 2.501]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.04}
        />
        <mesh
          name="business_center_004001"
          castShadow
          receiveShadow
          geometry={nodes.business_center_004001.geometry}
          material={materials.Mat}
          position={[-4.67, -0.014, -0.307]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.057}
        />
        <mesh
          name="business_center_004002"
          castShadow
          receiveShadow
          geometry={nodes.business_center_004002.geometry}
          material={materials.Mat}
          position={[2.756, -0.014, -4.179]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.057}
        />
        <mesh
          name="car"
          castShadow
          receiveShadow
          geometry={nodes.car.geometry}
          material={materials.Material}
          position={[-4.855, 0.008, 4.672]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <mesh
          name="car001"
          castShadow
          receiveShadow
          geometry={nodes.car001.geometry}
          material={materials.Material}
          position={[-0.063, 0.006, -0.796]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.103}
        />
        <mesh
          name="car002"
          castShadow
          receiveShadow
          geometry={nodes.car002.geometry}
          material={materials.Material}
          position={[3.749, 0.006, 1.839]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.09}
        />
        <mesh
          name="car003"
          castShadow
          receiveShadow
          geometry={nodes.car003.geometry}
          material={materials.Material}
          position={[-1.295, 0.006, -3.869]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.095}
        />
        <mesh
          name="car004"
          castShadow
          receiveShadow
          geometry={nodes.car004.geometry}
          material={materials.Material}
          position={[-2.856, 0.008, -1.447]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.095}
        />
        <mesh
          name="car005"
          castShadow
          receiveShadow
          geometry={nodes.car005.geometry}
          material={materials.Material}
          position={[-3.709, 0.006, -2.249]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.095}
        />
        <mesh
          name="house_middle_001001"
          castShadow
          receiveShadow
          geometry={nodes.house_middle_001001.geometry}
          material={materials.Mat}
          position={[-4.612, 0.006, 1.023]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.093}
        />
        <mesh
          name="house_middle_001002"
          castShadow
          receiveShadow
          geometry={nodes.house_middle_001002.geometry}
          material={materials.Mat}
          position={[-4.612, 0.006, -1.712]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.093}
        />
        <mesh
          name="house_middle_007002"
          castShadow
          receiveShadow
          geometry={nodes.house_middle_007002.geometry}
          material={materials.Mat}
          position={[-3.189, -0.014, -0.442]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.114}
        />
        <mesh
          name="house_middle_007003"
          castShadow
          receiveShadow
          geometry={nodes.house_middle_007003.geometry}
          material={materials.Mat}
          position={[3.851, -0.014, -4.215]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.114}
        />
        <mesh
          name="house_middle_007004"
          castShadow
          receiveShadow
          geometry={nodes.house_middle_007004.geometry}
          material={materials.Mat}
          position={[-4.948, -0.014, 3.187]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.138}
        />
        <mesh
          name="house_middle_010001"
          castShadow
          receiveShadow
          geometry={nodes.house_middle_010001.geometry}
          material={materials.Mat}
          position={[-4.839, -0.014, -3.613]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.09}
        />
        <group
          name="info_kiosk"
          position={[-0.501, 0.18, 3.09]}
          rotation={[0, 1.566, 0]}
          scale={[0.185, 0.209, 0.185]}
        >
          <mesh
            name="Cylinder"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder_1.geometry}
            material={materials["info-sign"]}
          />
        </group>
        <mesh
          name="park_005001"
          castShadow
          receiveShadow
          geometry={nodes.park_005001.geometry}
          material={materials["Material.002"]}
          position={[-0.076, 0.396, 2.364]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.19}
        />
        <mesh
          name="park_005003"
          castShadow
          receiveShadow
          geometry={nodes.park_005003.geometry}
          material={materials["Material.002"]}
          position={[-0.934, 0.396, 2.365]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.19}
        />
        <mesh
          name="park_005004"
          castShadow
          receiveShadow
          geometry={nodes.park_005004.geometry}
          material={materials["Material.002"]}
          position={[-0.071, 0.396, 3.801]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.19}
        />
        <mesh
          name="park_005005"
          castShadow
          receiveShadow
          geometry={nodes.park_005005.geometry}
          material={materials["Material.002"]}
          position={[0.356, 0.396, 3.1]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.19}
        />
        <mesh
          name="park_005006"
          castShadow
          receiveShadow
          geometry={nodes.park_005006.geometry}
          material={materials["Material.002"]}
          position={[-0.922, 0.396, 3.812]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.19}
        />
        <mesh
          name="park_005007"
          castShadow
          receiveShadow
          geometry={nodes.park_005007.geometry}
          material={materials["Material.002"]}
          position={[-1.344, 0.396, 3.063]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.19}
        />
        <mesh
          name="park_bench"
          castShadow
          receiveShadow
          geometry={nodes.park_bench.geometry}
          material={materials.Material}
          position={[0.15, 0.051, 3.462]}
          rotation={[Math.PI / 2, 0, -1.025]}
          scale={0.19}
        />
        <mesh
          name="park_bench001"
          castShadow
          receiveShadow
          geometry={nodes.park_bench001.geometry}
          material={materials.Material}
          position={[-1.149, 0.051, 2.693]}
          rotation={[Math.PI / 2, 0, 2.102]}
          scale={0.19}
        />
        <mesh
          name="park_bench002"
          castShadow
          receiveShadow
          geometry={nodes.park_bench002.geometry}
          material={materials.Material}
          position={[-0.486, 0.051, 3.829]}
          rotation={[Math.PI / 2, 0, -0.012]}
          scale={0.19}
        />
        <mesh
          name="park_bench003"
          castShadow
          receiveShadow
          geometry={nodes.park_bench003.geometry}
          material={materials.Material}
          position={[0.144, 0.051, 2.709]}
          rotation={[Math.PI / 2, 0, -2.099]}
          scale={0.19}
        />
        <mesh
          name="Plane"
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={materials["tv-poster-large"]}
          position={[-4.433, 1.491, 3.221]}
        />
        <mesh
          name="road_tile_bridge_2x2_007001"
          castShadow
          receiveShadow
          geometry={nodes.road_tile_bridge_2x2_007001.geometry}
          material={materials.Material}
          position={[-3.643, 0.025, -6.521]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.115, 0.112, 0.115]}
        />
        <group
          name="Roof_Top_house001"
          position={[1.326, 0.705, -0.72]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.002}
        />
        <group
          name="tv_tower_dish"
          position={[-3.707, 1.709, 3.479]}
          rotation={[0, -0.002, 0]}
          scale={0.273}
        >
          <mesh
            name="Cylinder001"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder001.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder001_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder001_1.geometry}
            material={materials["studio-side-graphic"]}
          />
        </group>
        <mesh
          name="zone1-marker-billboard"
          castShadow
          receiveShadow
          geometry={nodes["zone1-marker-billboard"].geometry}
          material={materials["marker-billboard"]}
          position={[0.797, 2.216, -3.502]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
          onClick={(e) => console.log('clicked billboard marker')}
        />
        <mesh
          name="zone1-marker-driving"
          castShadow
          receiveShadow
          geometry={nodes["zone1-marker-driving"].geometry}
          material={materials["marker-game-play"]}
          position={[3.635, 0.523, 4.546]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
          onClick={(e) => console.log('clicked driving marker')}
        />
        <mesh
          name="zone1-marker-exit"
          castShadow
          receiveShadow
          geometry={nodes["zone1-marker-exit"].geometry}
          material={materials["marker-zone1-exit"]}
          position={[-3.39, 0.523, -4.949]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
          onClick={(e) => console.log('clicked exit marker')}
        />
        <mesh
          name="zone1-marker-info"
          castShadow
          receiveShadow
          geometry={nodes["zone1-marker-info"].geometry}
          material={materials["marker-info"]}
          position={[-0.482, 0.909, 3.075]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
          onClick={(e) => console.log('clicked info marker')}
        />
        <mesh
          name="zone1-marker-school"
          castShadow
          receiveShadow
          geometry={nodes["zone1-marker-school"].geometry}
          material={materials["marker-university"]}
          position={[2.267, 1.248, -0.476]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
          onClick={(e) => console.log('clicked school marker')}
        />
        <mesh
          name="zone1-marker-store"
          castShadow
          receiveShadow
          geometry={nodes["zone1-marker-store"].geometry}
          material={materials["marker-store-user"]}
          position={[-1.047, 1.19, -0.61]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
          onClick={(e) => console.log('clicked store marker')}
        />
        <mesh
          name="zone1-marker-tv"
          castShadow
          receiveShadow
          geometry={nodes["zone1-marker-tv"].geometry}
          material={materials["marker-tv"]}
          position={[-3.416, 1.099, 3.004]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
          onClick={(e) => console.log('clicked tv studio marker')}
        />
        <group
          name="zone_1_school"
          position={[2.331, 0.449, -0.286]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.003}
        >
          <mesh
            name="Mesh2188"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2188.geometry}
            material={materials["white.001"]}
          />
          <mesh
            name="Mesh2188_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2188_1.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Mesh2188_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2188_2.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Mesh2188_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2188_3.geometry}
            material={materials.Grey}
          />
          <mesh
            name="Mesh2188_4"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2188_4.geometry}
            material={materials.Black}
          />
          <mesh
            name="Mesh2188_5"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2188_5.geometry}
            material={materials.School}
          />
        </group>
        <group
          name="currys_store_zone_1004"
          position={[-1.062, 0.008, -0.61]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.147}
        >
          <mesh
            name="currys_store_zone_1_1004"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1004.geometry}
            material={materials.Mat}
          />
          <mesh
            name="currys_store_zone_1_1004_1"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1004_1.geometry}
            material={materials["store logo"]}
          />
        </group>
        <mesh
          name="supermarket_008001"
          castShadow
          receiveShadow
          geometry={nodes.supermarket_008001.geometry}
          material={materials.Mat}
          position={[-3.399, 0.005, 1.012]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.103}
        />
        <group
          name="Cylinder045002"
          position={[-0.889, 0.021, 2.176]}
          rotation={[Math.PI, -0.936, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh2222"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2222.geometry}
            material={materials["04 - Default"]}
          />
          <mesh
            name="Mesh2222_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2222_1.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh2222_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2222_2.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh2222_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2222_3.geometry}
            material={materials["03 - Default"]}
          />
        </group>
        <group
          name="Hedra027003"
          position={[-1.108, 0.238, -1.685]}
          rotation={[-Math.PI / 2, 0, -2.299]}
          scale={0}
        >
          <mesh
            name="Mesh2219"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2219.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh2219_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2219_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Hedra027006"
          position={[0.493, 0.238, 5.008]}
          rotation={[-Math.PI / 2, 0, 2.15]}
          scale={0}
        >
          <mesh
            name="Mesh2219"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2219.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh2219_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2219_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Hedra027009"
          position={[2.721, 0.337, -2.005]}
          rotation={[-Math.PI / 2, 0, -3.083]}
          scale={0}
        >
          <mesh
            name="Mesh2219"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2219.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh2219_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh2219_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/zone1.glb");
