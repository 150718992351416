
import './App.css';
import { Canvas } from '@react-three/fiber'
import { Environment, GradientTexture, MapControls, Stage, Outlines, OrthographicCamera, Sparkles, AccumulativeShadows, RandomizedLight, } from '@react-three/drei'
import { Model } from './Model'
import { Model2 } from './Model2'
import { Model3 } from './Model3'
import { Model4 } from './Model4'
import { Model5 } from './Model5'
import { Zone2 } from './Zone2Model'

function App() {
  
  return (
    <Canvas shadows orthographic camera={{position:[4,3.4,4], zoom: 1, near:0.00000001, left:-1, right:1, top:1, bottom:-1}}>
      {/* <Sparkles size={1} scale={4} count={800} noise={[0,0,0]} /> */}
      
        {/* <Stage preset="soft" adjustCamera intensity={0.9} shadows="accumulative" environment="dawn" color="white"> */}
          <directionalLight color="white" position={[32, 24, 10]} castShadow intensity={3} shadow-mapSize-height={4096}
          shadow-mapSize-width={4096}
          shadow-radius={50}
          shadow-bias={-0.0001}/>
          {/* <mesh position={[0, 6, 0]} castShadow>
            <sphereGeometry args={[2,128,128]} />
            <meshStandardMaterial>
              <GradientTexture stops={[0, 0.25, 0.5, 0.75, 1]} colors={['#ffc223', '#ffc223','#f8ffe5', '#ef476f', '#ef476f']} size={1024} />
            </meshStandardMaterial>
            <Outlines thickness={0.02} color="black" />
          </mesh> */}
          <Model shadows castShadow receiveShadow />
          <Model2 shadows castShadow receiveShadow />
          <Model3 shadows castShadow receiveShadow />
          <Model4 shadows castShadow receiveShadow />
          <Model5 shadows castShadow receiveShadow />
          <AccumulativeShadows temporal frames={200} color="purple" colorBlend={0.9} opacity={1} scale={10} alphaTest={0.85}>
            <RandomizedLight amount={8} radius={4} ambient={0.8} position={[2, 3, 2]} bias={0.001} />
          </AccumulativeShadows>
          <Environment preset="sunset" background blur={0.8} intensity={0.5} scale={[20,20,20]} />
          {/* <mesh position={[0, -3, 0]} rotation={[ -Math.PI/2, 0, 0]} >
            <planeGeometry args={[20,20]} />
            <meshStandardMaterial />
          </mesh> */}
      {/*</Stage>*/}
     {/* <Environment preset="city" background blur={0.3} intensity={0.1} /> */}
      <MapControls />

    </Canvas>
  );
}

export default App;
