/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Model4(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/zone4.glb");
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="road002"
          castShadow
          receiveShadow
          geometry={nodes.road002.geometry}
          material={materials.Mat}
          position={[-12.743, 0.009, -25.514]}
          scale={0.236}
        />
        <mesh
          name="turbine-head-and-blades"
          castShadow
          receiveShadow
          geometry={nodes["turbine-head-and-blades"].geometry}
          material={materials.Mat}
          position={[-12.27, 2.301, -30.814]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.697}
        />
        <mesh
          name="turbine-body"
          castShadow
          receiveShadow
          geometry={nodes["turbine-body"].geometry}
          material={materials.Mat}
          position={[-12.266, 2.3, -30.944]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.697, 0.782, 0.796]}
        />
        <mesh
          name="turbine-head-and-blades2"
          castShadow
          receiveShadow
          geometry={nodes["turbine-head-and-blades2"].geometry}
          material={materials.Mat}
          position={[-14.163, 2.304, -30.814]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.697}
        />
        <mesh
          name="turbine-body001"
          castShadow
          receiveShadow
          geometry={nodes["turbine-body001"].geometry}
          material={materials.Mat}
          position={[-14.161, 2.3, -30.944]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.697, 0.782, 0.796]}
        />
        <mesh
          name="solar-panels004"
          castShadow
          receiveShadow
          geometry={nodes["solar-panels004"].geometry}
          material={materials.Mat}
          position={[-11.06, 0.961, -26.902]}
          rotation={[0, 0, -0.765]}
          scale={[0.605, 1.78, 2.656]}
        />
        <mesh
          name="university-zone-4"
          castShadow
          receiveShadow
          geometry={nodes["university-zone-4"].geometry}
          material={materials.Mat}
          position={[-10.719, 0.245, -26.382]}
          scale={0.943}
        />
        <mesh
          name="zone4-marker-store"
          castShadow
          receiveShadow
          geometry={nodes["zone4-marker-store"].geometry}
          material={materials["marker-store-user"]}
          position={[-16.502, 1.275, -30.291]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone4-marker-school"
          castShadow
          receiveShadow
          geometry={nodes["zone4-marker-school"].geometry}
          material={materials["marker-university"]}
          position={[-10.188, 1.536, -26.567]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone4-marker-turbine"
          castShadow
          receiveShadow
          geometry={nodes["zone4-marker-turbine"].geometry}
          material={materials["marker-turbine"]}
          position={[-13.216, 2.063, -30.502]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone4-marker-exit"
          castShadow
          receiveShadow
          geometry={nodes["zone4-marker-exit"].geometry}
          material={materials["marker-zone1-exit"]}
          position={[-18.871, 1.21, -29.237]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone4-marker-info"
          castShadow
          receiveShadow
          geometry={nodes["zone4-marker-info"].geometry}
          material={materials["marker-info"]}
          position={[-10.116, 0.838, -24.748]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone4-marker-game"
          castShadow
          receiveShadow
          geometry={nodes["zone4-marker-game"].geometry}
          material={materials["marker-game-play.002"]}
          position={[-12.435, 0.495, -22.417]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone4-marker-recycle"
          castShadow
          receiveShadow
          geometry={nodes["zone4-marker-recycle"].geometry}
          material={materials["marker-recycle"]}
          position={[-15.224, 1.341, -23.721]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <group
          name="conifer2_Cylinder005"
          position={[-9.172, 0.451, -21.742]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder002_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder002_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder002_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder002_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="stone_with_moss_3002"
          position={[-8.628, -0.031, -22.11]}
          rotation={[0, -1.046, 0]}
          scale={0.319}
        >
          <mesh
            name="Cube044"
            castShadow
            receiveShadow
            geometry={nodes.Cube044.geometry}
            material={materials["STONE 1.002"]}
          />
          <mesh
            name="Cube044_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube044_1.geometry}
            material={materials["Material.031"]}
          />
        </group>
        <mesh
          name="tower-block-curved001"
          castShadow
          receiveShadow
          geometry={nodes["tower-block-curved001"].geometry}
          material={materials.Mat}
          position={[-18.2, 1.723, -23.956]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.772}
        />
        <mesh
          name="tower-block-curved"
          castShadow
          receiveShadow
          geometry={nodes["tower-block-curved"].geometry}
          material={materials.Mat}
          position={[-18.238, 1.198, -25.468]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.772}
        />
        <mesh
          name="tower-block-1"
          castShadow
          receiveShadow
          geometry={nodes["tower-block-1"].geometry}
          material={materials.Material}
          position={[-12.793, 0.856, -24.973]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.584, 1.32, 0.584]}
        />
        <mesh
          name="solar-panels002"
          castShadow
          receiveShadow
          geometry={nodes["solar-panels002"].geometry}
          material={materials.Mat}
          position={[-18.457, 3.097, -23.969]}
          rotation={[0, 0, -0.765]}
          scale={[0.605, 1.78, 2.656]}
        />
        <mesh
          name="solar-panels003"
          castShadow
          receiveShadow
          geometry={nodes["solar-panels003"].geometry}
          material={materials.Mat}
          position={[-16.649, 0.777, -30.272]}
          rotation={[0, 0, -0.765]}
          scale={[0.605, 1.78, 2.656]}
        />
        <mesh
          name="tower-block-curved002"
          castShadow
          receiveShadow
          geometry={nodes["tower-block-curved002"].geometry}
          material={materials.Mat}
          position={[-12.557, 0.708, -26.419]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.772}
        />
        <mesh
          name="tower-block-curved003"
          castShadow
          receiveShadow
          geometry={nodes["tower-block-curved003"].geometry}
          material={materials.Mat}
          position={[-14.251, 1.284, -25.776]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.67}
        />
        <mesh
          name="solar-panels005"
          castShadow
          receiveShadow
          geometry={nodes["solar-panels005"].geometry}
          material={materials.Mat}
          position={[-14.515, 2.521, -25.781]}
          rotation={[0, 0, -0.765]}
          scale={[0.605, 1.78, 2.656]}
        />
        <mesh
          name="tower-block-1001"
          castShadow
          receiveShadow
          geometry={nodes["tower-block-1001"].geometry}
          material={materials.Material}
          position={[-11.746, 0.426, -24.973]}
          scale={[0.584, 1.32, 0.584]}
        />
        <mesh
          name="solar-panels006"
          castShadow
          receiveShadow
          geometry={nodes["solar-panels006"].geometry}
          material={materials.Mat}
          position={[-16.328, 1.052, -24.59]}
          rotation={[0, 0, -0.765]}
          scale={[0.605, 1.78, 2.656]}
        />
        <mesh
          name="lake"
          castShadow
          receiveShadow
          geometry={nodes.lake.geometry}
          material={materials.Mat}
        />
        <mesh
          name="solar-panels007"
          castShadow
          receiveShadow
          geometry={nodes["solar-panels007"].geometry}
          material={materials.Mat}
          position={[-12.861, 0.042, -22.775]}
          rotation={[0.765, -Math.PI / 2, 0]}
          scale={[0.605, 1.78, 2.656]}
        />
        <mesh
          name="welcome-sign-graphic"
          castShadow
          receiveShadow
          geometry={nodes["welcome-sign-graphic"].geometry}
          material={materials["zone4-welcome"]}
          position={[-18.192, 1.505, -21.856]}
        />
        <mesh
          name="house1"
          castShadow
          receiveShadow
          geometry={nodes.house1.geometry}
          material={materials.Mat}
          position={[-14.201, 0.318, -23.929]}
          rotation={[0, -0.967, 0]}
          scale={[0.351, 0.392, 0.351]}
        />
        <mesh
          name="house2"
          castShadow
          receiveShadow
          geometry={nodes.house2.geometry}
          material={materials.Mat}
          position={[-14.149, 0.318, -22.781]}
          rotation={[0, 0.666, 0]}
          scale={[0.351, 0.392, 0.351]}
        />
        <mesh
          name="house3"
          castShadow
          receiveShadow
          geometry={nodes.house3.geometry}
          material={materials.Mat}
          position={[-15.962, 0.318, -28.387]}
          rotation={[0, 0.84, 0]}
          scale={[0.351, 0.392, 0.351]}
        />
        <mesh
          name="info-dome"
          castShadow
          receiveShadow
          geometry={nodes["info-dome"].geometry}
          material={materials.Mat}
          position={[-10.095, 0.213, -24.696]}
          rotation={[0, 0.146, 0]}
          scale={0.44}
        />
        <group
          name="currys_store_zone_4001"
          position={[-16.54, -0.001, -30.286]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.147}
        >
          <mesh
            name="currys_store_zone_1_1008"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1008.geometry}
            material={materials.Mat}
          />
          <mesh
            name="currys_store_zone_1_1008_1"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1008_1.geometry}
            material={materials["store logo-new"]}
          />
        </group>
        <mesh
          name="solar-panels012"
          castShadow
          receiveShadow
          geometry={nodes["solar-panels012"].geometry}
          material={materials.Mat}
          position={[-12.861, 0.042, -22.775]}
          rotation={[0.765, -Math.PI / 2, 0]}
          scale={[0.605, 1.78, 2.656]}
        />
        <mesh
          name="Cylinder015"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder015.geometry}
          material={materials.Mat}
          position={[-18.192, 1.891, -22.566]}
          rotation={[-0.873, 0, 0]}
        />
        <mesh
          name="conveyer-belt"
          castShadow
          receiveShadow
          geometry={nodes["conveyer-belt"].geometry}
          material={materials.Mat}
          position={[-16.106, 0.081, -23.6]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[4.827, 0.168, 1]}
        />
        <mesh
          name="recycling-plant"
          castShadow
          receiveShadow
          geometry={nodes["recycling-plant"].geometry}
          material={materials.Mat}
          position={[-16.105, 0.52, -24.635]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.488, 0.521, 0.762]}
        />
        <mesh
          name="recycling-sign-small"
          castShadow
          receiveShadow
          geometry={nodes["recycling-sign-small"].geometry}
          material={materials["trash-sign-1"]}
          position={[-16.104, 0.4, -23.587]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1, 1, 0.708]}
        />
        <mesh
          name="recycling-sign-large"
          castShadow
          receiveShadow
          geometry={nodes["recycling-sign-large"].geometry}
          material={materials["recycling sign large"]}
          position={[-15.342, 0.561, -24.566]}
        />
        <group
          name="stone_with_moss_3003"
          position={[-9.91, -0.031, -22.398]}
          rotation={[-Math.PI, 0.736, -Math.PI]}
          scale={0.193}
        >
          <mesh
            name="Cube045"
            castShadow
            receiveShadow
            geometry={nodes.Cube045.geometry}
            material={materials["STONE 1.002"]}
          />
          <mesh
            name="Cube045_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube045_1.geometry}
            material={materials["Material.031"]}
          />
        </group>
        <mesh
          name="dividing-hedge001"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge001"].geometry}
          material={materials.Mat}
          position={[-15.055, 0.104, -22.921]}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.573}
        />
        <mesh
          name="dividing-hedge002"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge002"].geometry}
          material={materials.Mat}
          position={[-11.976, 0.104, -24.274]}
          scale={0.573}
        />
        <mesh
          name="dividing-hedge003"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge003"].geometry}
          material={materials.Mat}
          position={[-15.601, 0.104, -22.769]}
          scale={0.573}
        />
        <mesh
          name="dividing-hedge004"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge004"].geometry}
          material={materials.Mat}
          position={[-16.92, 0.104, -22.936]}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.573}
        />
        <mesh
          name="dividing-hedge005"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge005"].geometry}
          material={materials.Mat}
          position={[-16.955, 0.104, -22.769]}
          scale={0.573}
        />
        <mesh
          name="dividing-hedge006"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge006"].geometry}
          material={materials.Mat}
          position={[-14.777, 0.104, -24.907]}
          scale={0.573}
        />
        <mesh
          name="dividing-hedge007"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge007"].geometry}
          material={materials.Mat}
          position={[-9.865, 0.089, -26.579]}
          scale={[0.49, 0.443, 0.573]}
        />
        <mesh
          name="dividing-hedge008"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge008"].geometry}
          material={materials.Mat}
          position={[-12.744, 0.075, -27.529]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.49, 0.443, 0.573]}
        />
        <mesh
          name="dividing-hedge009"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge009"].geometry}
          material={materials.Mat}
          position={[-9.417, 0.086, -27.164]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.49, 0.443, 0.573]}
        />
        <mesh
          name="dividing-hedge010"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge010"].geometry}
          material={materials.Mat}
          position={[-9.426, 0.082, -25.912]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.49, 0.443, 0.573]}
        />
        <mesh
          name="dividing-hedge011"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge011"].geometry}
          material={materials.Mat}
          position={[-12.649, 0.075, -28.88]}
          scale={[0.49, 0.443, 0.573]}
        />
        <mesh
          name="dividing-hedge012"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge012"].geometry}
          material={materials.Mat}
          position={[-11.619, 0.079, -25.723]}
          scale={[0.49, 0.443, 0.573]}
        />
        <mesh
          name="dividing-hedge013"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge013"].geometry}
          material={materials.Mat}
          position={[-9.865, 0.079, -27.128]}
          scale={[0.49, 0.443, 0.573]}
        />
        <mesh
          name="dividing-hedge014"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge014"].geometry}
          material={materials.Mat}
          position={[-13.401, 0.087, -28.524]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[0.49, 0.443, 0.573]}
        />
        <mesh
          name="dividing-hedge015"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge015"].geometry}
          material={materials.Mat}
          position={[-13.284, 0.104, -24.274]}
          scale={0.573}
        />
        <group
          name="stone_with_moss_3004"
          position={[-13.316, -0.073, -23.666]}
          rotation={[0, -0.469, 0]}
          scale={0.076}
        >
          <mesh
            name="Cube015"
            castShadow
            receiveShadow
            geometry={nodes.Cube015.geometry}
            material={materials["STONE 1.002"]}
          />
          <mesh
            name="Cube015_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube015_1.geometry}
            material={materials["Material.031"]}
          />
        </group>
        <group
          name="stone_with_moss_3005"
          position={[-13.229, -0.078, -24.019]}
          rotation={[0, 0.484, 0]}
          scale={0.076}
        >
          <mesh
            name="Cube029"
            castShadow
            receiveShadow
            geometry={nodes.Cube029.geometry}
            material={materials["STONE 1.002"]}
          />
          <mesh
            name="Cube029_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube029_1.geometry}
            material={materials["Material.031"]}
          />
        </group>
        <mesh
          name="jetty"
          castShadow
          receiveShadow
          geometry={nodes.jetty.geometry}
          material={materials.Mat}
          position={[-13.291, -0.012, -23.834]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[1.405, 0.036, 3.539]}
        />
        <mesh
          name="dividing-hedge016"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge016"].geometry}
          material={materials.Mat}
          position={[-13.251, 0.112, -23.086]}
          scale={0.573}
        />
        <mesh
          name="dividing-hedge017"
          castShadow
          receiveShadow
          geometry={nodes["dividing-hedge017"].geometry}
          material={materials.Mat}
          position={[-12.09, 0.133, -23.052]}
          scale={0.573}
        />
        <group
          name="conifer2_Cylinder002"
          position={[-13.092, 0.628, -30.285]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder002_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder002_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder002_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder002_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder003"
          position={[-10.336, 0.643, -30.29]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder003_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder003_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder003_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder003_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder004"
          position={[-8.946, 1.169, -31.083]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder004_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder004_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder004_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder004_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder006"
          position={[-15.738, 1.284, -31.163]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder004_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder004_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder004_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder004_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder007"
          position={[-9.957, 0.47, -22.833]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder005_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder005_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder005_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder005_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder008"
          position={[-10.826, 0.443, -24.507]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder006_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder006_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder006_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder006_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="Cylinder016001"
          position={[-8.19, 1.394, -30.27]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh016"
            castShadow
            receiveShadow
            geometry={nodes.Mesh016.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh016_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh016_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh016_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh016_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh016_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh016_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Cylinder016003"
          position={[-17.588, 1.426, -30.281]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh016"
            castShadow
            receiveShadow
            geometry={nodes.Mesh016.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh016_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh016_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh016_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh016_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh016_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh016_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="conifer5_Cylinder002"
          position={[-10.011, 1.187, -31.003]}
          rotation={[Math.PI / 2, 0, 0.552]}
          scale={[0.139, 0.139, 0.185]}
        >
          <mesh
            name="conifer5_Cylinder002_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder002_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer5_Cylinder002_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder002_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer5_Cylinder003"
          position={[-9.332, 0.629, -30.299]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.139, 0.139, 0.151]}
        >
          <mesh
            name="conifer5_Cylinder003_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder003_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer5_Cylinder003_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder003_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer5_Cylinder001"
          position={[-10.996, 0.992, -31.019]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.102, 0.102, 0.129]}
        >
          <mesh
            name="conifer5_Cylinder001_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder001_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer5_Cylinder001_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder001_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer5_Cylinder008"
          position={[-18.023, 1.181, -31.142]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.102, 0.102, 0.129]}
        >
          <mesh
            name="conifer5_Cylinder001_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder001_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer5_Cylinder001_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder001_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer5_Cylinder010"
          position={[-11.733, 0.615, -28.398]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.139, 0.139, 0.151]}
        >
          <mesh
            name="conifer5_Cylinder003_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder003_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer5_Cylinder003_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder003_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer5_Cylinder009"
          position={[-16.86, 1.299, -31.12]}
          rotation={[Math.PI / 2, 0, 0.552]}
          scale={[0.139, 0.139, 0.185]}
        >
          <mesh
            name="conifer5_Cylinder002_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder002_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer5_Cylinder002_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder002_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder009"
          position={[-16.05, 0.443, -21.488]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder007_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder007_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder007_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder007_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder010"
          position={[-8.261, 0.443, -29.285]}
          rotation={[Math.PI / 2, 0, 1.059]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder008_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder008_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder008_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder008_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder011"
          position={[-16.705, 0.443, -26.132]}
          rotation={[Math.PI / 2, 0, 1.059]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder009_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder009_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder009_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder009_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <mesh
          name="stone_2002"
          castShadow
          receiveShadow
          geometry={nodes.stone_2002.geometry}
          material={materials["STONE 1.003"]}
          position={[-9.201, -0.031, -21.36]}
          rotation={[0, 0.017, 0]}
          scale={0.197}
        />
        <group
          name="conifer2_Cylinder012"
          position={[-8.261, 0.443, -27.404]}
          rotation={[Math.PI / 2, 0, 1.059]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder010_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder010_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder010_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder010_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder013"
          position={[-8.261, 0.443, -25.518]}
          rotation={[Math.PI / 2, 0, 1.059]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder011_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder011_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder011_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder011_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder014"
          position={[-8.261, 0.443, -23.634]}
          rotation={[Math.PI / 2, 0, 1.059]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder012_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder012_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder012_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder012_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <mesh
          name="stone_2003"
          castShadow
          receiveShadow
          geometry={nodes.stone_2003.geometry}
          material={materials["STONE 1.003"]}
          position={[-8.381, -0.031, -22.343]}
          rotation={[0, -1.387, 0]}
          scale={0.197}
        />
        <group
          name="conifer5_Cylinder011"
          position={[-15.074, 0.782, -30.275]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.102, 0.102, 0.129]}
        >
          <mesh
            name="conifer5_Cylinder008_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder008_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer5_Cylinder008_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder008_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder015"
          position={[-15.651, 0.443, -25.914]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder013_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder013_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder013_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder013_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer5_Cylinder007"
          position={[-9.628, 0.755, -21.413]}
          rotation={[Math.PI / 2, 0, 0.552]}
          scale={[0.139, 0.139, 0.185]}
        >
          <mesh
            name="conifer5_Cylinder007_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder007_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer5_Cylinder007_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer5_Cylinder007_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="tree"
          position={[-8.665, 1.227, -22.815]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh017"
            castShadow
            receiveShadow
            geometry={nodes.Mesh017.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh017_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh017_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh017_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh017_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh017_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh017_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree001"
          position={[-10.296, 0.588, -25.331]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh014"
            castShadow
            receiveShadow
            geometry={nodes.Mesh014.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh014_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh014_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh014_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh014_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh014_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh014_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree002"
          position={[-10.334, 0.588, -28.301]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh015"
            castShadow
            receiveShadow
            geometry={nodes.Mesh015.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh015_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh015_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh015_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh015_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh015_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh015_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree003"
          position={[-15.184, 0.588, -21.044]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh024"
            castShadow
            receiveShadow
            geometry={nodes.Mesh024.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh024_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh024_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh024_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh024_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh024_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh024_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree004"
          position={[-8.223, 0.588, -28.312]}
          rotation={[Math.PI, -0.25, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh088"
            castShadow
            receiveShadow
            geometry={nodes.Mesh088.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh088_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh088_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh088_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh088_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh088_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh088_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree006"
          position={[-8.223, 0.588, -26.432]}
          rotation={[Math.PI, -0.25, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh143"
            castShadow
            receiveShadow
            geometry={nodes.Mesh143.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh143_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh143_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh143_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh143_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh143_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh143_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree007"
          position={[-8.223, 0.588, -24.546]}
          rotation={[Math.PI, -0.25, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh144"
            castShadow
            receiveShadow
            geometry={nodes.Mesh144.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh144_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh144_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh144_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh144_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh144_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh144_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree008"
          position={[-14.132, 0.748, -30.248]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh237"
            castShadow
            receiveShadow
            geometry={nodes.Mesh237.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh237_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh237_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh237_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh237_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh237_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh237_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree009"
          position={[-12.092, 0.748, -30.234]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh270"
            castShadow
            receiveShadow
            geometry={nodes.Mesh270.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh270_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh270_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh270_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh270_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh270_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh270_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree010"
          position={[-11.268, 0.748, -30.248]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh275"
            castShadow
            receiveShadow
            geometry={nodes.Mesh275.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh275_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh275_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh275_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh275_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh275_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh275_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree011"
          position={[-13.384, 0.588, -22.434]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh276"
            castShadow
            receiveShadow
            geometry={nodes.Mesh276.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh276_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh276_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh276_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh276_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh276_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh276_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree012"
          position={[-13.817, 0.588, -24.678]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh277"
            castShadow
            receiveShadow
            geometry={nodes.Mesh277.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh277_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh277_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh277_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh277_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh277_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh277_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree013"
          position={[-16.406, 0.588, -26.576]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh278"
            castShadow
            receiveShadow
            geometry={nodes.Mesh278.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh278_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh278_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh278_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh278_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh278_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh278_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree014"
          position={[-17.409, 0.842, -21.044]}
          rotation={[Math.PI, -1.565, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh279"
            castShadow
            receiveShadow
            geometry={nodes.Mesh279.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh279_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh279_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh279_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh279_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh279_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh279_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree005"
          position={[-15.773, 0.588, -27.44]}
          rotation={[Math.PI, -0.25, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh142"
            castShadow
            receiveShadow
            geometry={nodes.Mesh142.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh142_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh142_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh142_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh142_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh142_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh142_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="conifer2_Cylinder016"
          position={[-17.224, 0.443, -27.888]}
          rotation={[Math.PI / 2, 0, 1.059]}
          scale={0.104}
        >
          <mesh
            name="conifer2_Cylinder014_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder014_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder014_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder014_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="tree015"
          position={[-15.112, 0.588, -28.301]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh280"
            castShadow
            receiveShadow
            geometry={nodes.Mesh280.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh280_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh280_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh280_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh280_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh280_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh280_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree016"
          position={[-14.646, 0.588, -23.389]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh281"
            castShadow
            receiveShadow
            geometry={nodes.Mesh281.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh281_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh281_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh281_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh281_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh281_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh281_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="tree017"
          position={[-14.646, 0.588, -24.372]}
          rotation={[Math.PI, -1.309, Math.PI]}
          scale={0}
        >
          <mesh
            name="Mesh282"
            castShadow
            receiveShadow
            geometry={nodes.Mesh282.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh282_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh282_1.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh282_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh282_2.geometry}
            material={materials["02 - Default"]}
          />
          <mesh
            name="Mesh282_3"
            castShadow
            receiveShadow
            geometry={nodes.Mesh282_3.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="conifer2_Cylinder017"
          position={[-13.54, 0.389, -23.319]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.084}
        >
          <mesh
            name="conifer2_Cylinder015_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder015_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder015_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder015_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="conifer2_Cylinder018"
          position={[-11.1, 0.389, -23.166]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.084}
        >
          <mesh
            name="conifer2_Cylinder016_1"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder016_1.geometry}
            material={materials["Material.003"]}
          />
          <mesh
            name="conifer2_Cylinder016_2"
            castShadow
            receiveShadow
            geometry={nodes.conifer2_Cylinder016_2.geometry}
            material={materials["Material.007"]}
          />
        </group>
        <group
          name="base_zone_4"
          position={[-13.282, -1.272, -26.235]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[5.649, 1.271, 5.649]}
        >
          <mesh
            name="Cube014"
            castShadow
            receiveShadow
            geometry={nodes.Cube014.geometry}
            material={materials.road}
          />
          <mesh
            name="Cube014_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube014_1.geometry}
            material={materials.Mat}
          />
        </group>
        <mesh
          name="road_tile_bridge_2x2_007007"
          castShadow
          receiveShadow
          geometry={nodes.road_tile_bridge_2x2_007007.geometry}
          material={materials.Material}
          position={[-19.865, 0.025, -29.362]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.119}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/zone4.glb");
