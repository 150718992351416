/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Model3(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/zone3.glb");
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="base_zone_3"
          position={[-13.255, -1.272, -13.063]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[5.649, 1.271, 5.649]}
        >
          <mesh
            name="Cube013"
            castShadow
            receiveShadow
            geometry={nodes.Cube013.geometry}
            material={materials.road}
          />
          <mesh
            name="Cube013_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube013_1.geometry}
            material={materials.Mat}
          />
        </group>
        <mesh
          name="road"
          castShadow
          receiveShadow
          geometry={nodes.road.geometry}
          material={materials.Mat}
          position={[-7.811, 0.009, -15.857]}
        />
        <mesh
          name="road_tile_bridge_2x2_007006"
          castShadow
          receiveShadow
          geometry={nodes.road_tile_bridge_2x2_007006.geometry}
          material={materials.Material}
          position={[-16.843, 0.025, -19.637]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.119}
        />
        <mesh
          name="landscape_entertainment_010001"
          castShadow
          receiveShadow
          geometry={nodes.landscape_entertainment_010001.geometry}
          material={materials["Material.035"]}
          position={[-10.832, 0.018, -10.146]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.153, 0.153, 0.164]}
        />
        <group
          name="currys_store_zone_1005"
          position={[-10.296, 0.008, -16.652]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.147}
        >
          <mesh
            name="currys_store_zone_1_1005"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1005.geometry}
            material={materials.Mat}
          />
          <mesh
            name="currys_store_zone_1_1005_1"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1005_1.geometry}
            material={materials["store logo"]}
          />
        </group>
        <mesh
          name="house_middle_010002"
          castShadow
          receiveShadow
          geometry={nodes.house_middle_010002.geometry}
          material={materials.Mat}
          position={[-17.646, -0.014, -9.605]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.09}
        />
        <mesh
          name="house_middle_010003"
          castShadow
          receiveShadow
          geometry={nodes.house_middle_010003.geometry}
          material={materials.Mat}
          position={[-17.646, -0.014, -10.7]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.09}
        />
        <mesh
          name="Cylinder005"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder005.geometry}
          material={materials["zone3-prism-signs-v2.001"]}
          position={[-13.508, 0.368, -10.352]}
          rotation={[0, -Math.PI / 3, 0]}
          scale={[0.839, 0.763, 0.839]}
        />
        <group
          name="zone3-welcome-sign"
          position={[-8.481, 1.002, -16.562]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.08}
        >
          <mesh
            name="zone3-welcome-sign-mesh"
            castShadow
            receiveShadow
            geometry={nodes["zone3-welcome-sign-mesh"].geometry}
            material={materials["Material.001"]}
          />
          <mesh
            name="zone3-welcome-sign-mesh_1"
            castShadow
            receiveShadow
            geometry={nodes["zone3-welcome-sign-mesh_1"].geometry}
            material={materials.Mat}
          />
          <mesh
            name="zone3-welcome-sign-mesh_2"
            castShadow
            receiveShadow
            geometry={nodes["zone3-welcome-sign-mesh_2"].geometry}
            material={materials["sports-zone-welcome-sign"]}
          />
        </group>
        <mesh
          name="hotel_007001"
          castShadow
          receiveShadow
          geometry={nodes.hotel_007001.geometry}
          material={materials["Material.035"]}
          position={[-17.417, 0, -14.327]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.11}
        />
        <mesh
          name="building-glass-and-steps"
          castShadow
          receiveShadow
          geometry={nodes["building-glass-and-steps"].geometry}
          material={materials["city.001"]}
          position={[-17.927, -0.058, -13.02]}
          rotation={[0, -1.571, 0]}
          scale={[0.105, 0.126, 0.075]}
        />
        <mesh
          name="car010"
          castShadow
          receiveShadow
          geometry={nodes.car010.geometry}
          material={materials.Material}
          position={[-10.722, 0.008, -11.595]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <mesh
          name="car011"
          castShadow
          receiveShadow
          geometry={nodes.car011.geometry}
          material={materials.Material}
          position={[-10.151, 0.008, -12.423]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.103}
        />
        <mesh
          name="car012"
          castShadow
          receiveShadow
          geometry={nodes.car012.geometry}
          material={materials.Material}
          position={[-10.722, 0.008, -12.873]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <mesh
          name="car013"
          castShadow
          receiveShadow
          geometry={nodes.car013.geometry}
          material={materials.Material}
          position={[-16.009, 0.008, -11.672]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <mesh
          name="car014"
          castShadow
          receiveShadow
          geometry={nodes.car014.geometry}
          material={materials.Material}
          position={[-15.442, 0.008, -12.417]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.103}
        />
        <mesh
          name="car015"
          castShadow
          receiveShadow
          geometry={nodes.car015.geometry}
          material={materials.Material}
          position={[-9.977, 0.008, -15.344]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.103}
        />
        <mesh
          name="car016"
          castShadow
          receiveShadow
          geometry={nodes.car016.geometry}
          material={materials.Material}
          position={[-16.018, 0.008, -12.852]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <mesh
          name="Cube023"
          castShadow
          receiveShadow
          geometry={nodes.Cube023.geometry}
          material={materials.Mat}
          position={[-15.128, 0.125, -10.083]}
          scale={[11.864, 2.735, 11.864]}
        />
        <mesh
          name="car017"
          castShadow
          receiveShadow
          geometry={nodes.car017.geometry}
          material={materials.Material}
          position={[-12.377, 0.008, -16.993]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <mesh
          name="car018"
          castShadow
          receiveShadow
          geometry={nodes.car018.geometry}
          material={materials.Material}
          position={[-16.018, 0.008, -16.959]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <mesh
          name="car019"
          castShadow
          receiveShadow
          geometry={nodes.car019.geometry}
          material={materials.Material}
          position={[-12.095, 0.008, -16.265]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <mesh
          name="car020"
          castShadow
          receiveShadow
          geometry={nodes.car020.geometry}
          material={materials.Material}
          position={[-15.729, 0.008, -13.61]}
          rotation={[Math.PI / 2, 0, -Math.PI]}
          scale={0.103}
        />
        <mesh
          name="transport_bus_005001"
          castShadow
          receiveShadow
          geometry={nodes.transport_bus_005001.geometry}
          material={materials["Material.035"]}
          position={[-11.91, 0.03, -11.533]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.1}
        />
        <mesh
          name="transport_bus_010001"
          castShadow
          receiveShadow
          geometry={nodes.transport_bus_010001.geometry}
          material={materials["Material.035"]}
          position={[-14.05, 0.03, -11.498]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.1}
        />
        <mesh
          name="Cylinder007"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder007.geometry}
          material={materials["zone3-prism-signs-v2.001"]}
          position={[-12.627, 0.362, -10.356]}
          rotation={[0, Math.PI / 3, 0]}
          scale={[0.839, 0.763, 0.839]}
        />
        <mesh
          name="Cube034"
          castShadow
          receiveShadow
          geometry={nodes.Cube034.geometry}
          material={materials.Mat}
          position={[-14.228, 0.073, -9.655]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[-1, -0.053, -1.383]}
        />
        <mesh
          name="Cube035"
          castShadow
          receiveShadow
          geometry={nodes.Cube035.geometry}
          material={materials.Mat}
          position={[-14.656, 0.422, -9.655]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[-1, -0.053, -1.383]}
        />
        <mesh
          name="hotel_009001"
          castShadow
          receiveShadow
          geometry={nodes.hotel_009001.geometry}
          material={materials["Material.035"]}
          position={[-8.434, 0.004, -13.987]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.1, 0.1, 0.084]}
        />
        <mesh
          name="house_small_1_008001"
          castShadow
          receiveShadow
          geometry={nodes.house_small_1_008001.geometry}
          material={materials["Material.035"]}
          position={[-8.411, 0.003, -11.765]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.1}
        />
        <mesh
          name="hotel_009002"
          castShadow
          receiveShadow
          geometry={nodes.hotel_009002.geometry}
          material={materials["Material.035"]}
          position={[-8.414, 0.003, -9.906]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={[0.1, 0.1, 0.084]}
        />
        <group
          name="zone3-billboard-joined"
          position={[-13.933, 1.366, -17.475]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.108}
        >
          <mesh
            name="zone3-billboard-joined-mesh"
            castShadow
            receiveShadow
            geometry={nodes["zone3-billboard-joined-mesh"].geometry}
            material={materials["billboard-1"]}
          />
          <mesh
            name="zone3-billboard-joined-mesh_1"
            castShadow
            receiveShadow
            geometry={nodes["zone3-billboard-joined-mesh_1"].geometry}
            material={materials["Material.001"]}
          />
          <mesh
            name="zone3-billboard-joined-mesh_2"
            castShadow
            receiveShadow
            geometry={nodes["zone3-billboard-joined-mesh_2"].geometry}
            material={materials.Mat}
          />
        </group>
        <mesh
          name="Cylinder006"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006.geometry}
          material={materials["zone3-prism-signs-v2"]}
          position={[-13.504, 0.368, -9.374]}
          rotation={[0, Math.PI / 3, 0]}
          scale={[0.839, 0.763, 0.839]}
        />
        <mesh
          name="Cylinder008"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder008.geometry}
          material={materials["zone3-prism-signs-v2.001"]}
          position={[-12.63, 0.362, -9.375]}
          rotation={[0, -Math.PI / 3, 0]}
          scale={[0.839, 0.763, 0.839]}
        />
        <mesh
          name="football_pitch"
          castShadow
          receiveShadow
          geometry={nodes.football_pitch.geometry}
          material={materials["Material.035"]}
          position={[-13.054, 0.023, -14.202]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.251}
        />
        <mesh
          name="grass"
          castShadow
          receiveShadow
          geometry={nodes.grass.geometry}
          material={materials.Mat}
          position={[-13.054, 0.024, -14.198]}
        />
        <group name="stadium_outer" position={[-12.319, 0.581, -13.404]}>
          <mesh
            name="Cylinder022"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder022.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder022_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder022_1.geometry}
            material={materials["stadium-main-sign"]}
          />
          <mesh
            name="Cylinder022_2"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder022_2.geometry}
            material={materials["stadium-secondary-sign"]}
          />
          <mesh
            name="Cylinder022_3"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder022_3.geometry}
            material={materials["stadium-kiosk-sign"]}
          />
        </group>
        <mesh
          name="seating"
          castShadow
          receiveShadow
          geometry={nodes.seating.geometry}
          material={materials.Mat}
          position={[-13.054, 0.028, -14.92]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={[-1.222, -0.036, -0.045]}
        />
        <mesh
          name="Cube"
          castShadow
          receiveShadow
          geometry={nodes.Cube.geometry}
          material={nodes.Cube.material}
          position={[-13.054, -0.001, -14.2]}
          scale={[1.169, 0.047, 0.623]}
        />
        <mesh
          name="floodlight"
          castShadow
          receiveShadow
          geometry={nodes.floodlight.geometry}
          material={materials.Mat}
          position={[-13.932, 1.347, -15.293]}
          rotation={[0.388, 0.43, 2.973]}
          scale={[-0.211, -0.175, -0.009]}
        />
        <mesh
          name="floodlight001"
          castShadow
          receiveShadow
          geometry={nodes.floodlight001.geometry}
          material={materials.Mat}
          position={[-12.18, 1.347, -13.109]}
          rotation={[2.748, -0.458, -0.181]}
          scale={[-0.211, -0.175, -0.009]}
        />
        <mesh
          name="floodlight002"
          castShadow
          receiveShadow
          geometry={nodes.floodlight002.geometry}
          material={materials.Mat}
          position={[-12.187, 1.347, -15.286]}
          rotation={[0.398, -0.479, -2.95]}
          scale={[-0.211, -0.175, -0.009]}
        />
        <mesh
          name="floodlight003"
          castShadow
          receiveShadow
          geometry={nodes.floodlight003.geometry}
          material={materials.Mat}
          position={[-13.877, 1.347, -13.144]}
          rotation={[2.747, 0.463, 0.183]}
          scale={[-0.211, -0.175, -0.009]}
        />
        <mesh
          name="zone3-marker-basketball"
          castShadow
          receiveShadow
          geometry={nodes["zone3-marker-basketball"].geometry}
          material={materials["marker-game-play.001"]}
          position={[-10.598, 1.08, -9.983]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone3-marker-football"
          castShadow
          receiveShadow
          geometry={nodes["zone3-marker-football"].geometry}
          material={materials["marker-game-play.002"]}
          position={[-13.052, 1.124, -14.225]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone3-marker-store"
          castShadow
          receiveShadow
          geometry={nodes["zone3-marker-store"].geometry}
          material={materials["marker-store-user"]}
          position={[-10.22, 1.275, -16.827]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone3-marker-school"
          castShadow
          receiveShadow
          geometry={nodes["zone3-marker-school"].geometry}
          material={materials["marker-university"]}
          position={[-17.554, 1.654, -12.753]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone3-marker-info"
          castShadow
          receiveShadow
          geometry={nodes["zone3-marker-info"].geometry}
          material={materials["marker-info"]}
          position={[-10.843, 1.213, -14.051]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone3-marker-exit"
          castShadow
          receiveShadow
          geometry={nodes["zone3-marker-exit"].geometry}
          material={materials["marker-zone1-exit"]}
          position={[-16.625, 0.523, -18.01]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone3-marker-slot"
          castShadow
          receiveShadow
          geometry={nodes["zone3-marker-slot"].geometry}
          material={materials["marker-tv"]}
          position={[-12.634, 1.099, -9.253]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="house_middle_010004"
          castShadow
          receiveShadow
          geometry={nodes.house_middle_010004.geometry}
          material={materials.Mat}
          position={[-17.664, -0.014, -16.28]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.09}
        />
        <mesh
          name="hotel_007002"
          castShadow
          receiveShadow
          geometry={nodes.hotel_007002.geometry}
          material={materials["Material.035"]}
          position={[-17.417, 0, -15.028]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.11}
        />
        <group
          name="Sphere012001"
          position={[-13.84, 0.474, -7.729]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.004}
        >
          <mesh
            name="Mesh004"
            castShadow
            receiveShadow
            geometry={nodes.Mesh004.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh004_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh004_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Hedra027001"
          position={[-12.067, 0.297, -7.869]}
          rotation={[-Math.PI / 2, 0, 1.037]}
          scale={0}
        >
          <mesh
            name="Mesh005"
            castShadow
            receiveShadow
            geometry={nodes.Mesh005.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh005_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh005_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="GeoSphere050001"
          position={[-16.161, 0.263, -8.049]}
          rotation={[Math.PI / 2, 0, Math.PI / 4]}
          scale={0}
        >
          <mesh
            name="Mesh006"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh006_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="GeoSphere050004"
          position={[-13.25, 0.263, -17.641]}
          rotation={[Math.PI / 2, 0, -0.072]}
          scale={0}
        >
          <mesh
            name="Mesh006"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh006_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="GeoSphere050006"
          position={[-9.968, 0.263, -17.907]}
          rotation={[Math.PI / 2, 0, Math.PI / 4]}
          scale={0}
        >
          <mesh
            name="Mesh006"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh006_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="GeoSphere050007"
          position={[-15.181, 0.263, -8.095]}
          rotation={[Math.PI / 2, 0, -0.072]}
          scale={0}
        >
          <mesh
            name="Mesh006"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh006_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Hedra027002"
          position={[-17.397, 0.297, -8.444]}
          rotation={[-Math.PI / 2, 0, 1.037]}
          scale={0}
        >
          <mesh
            name="Mesh005"
            castShadow
            receiveShadow
            geometry={nodes.Mesh005.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh005_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh005_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Sphere012002"
          position={[-8.556, 0.474, -8.138]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.004}
        >
          <mesh
            name="Mesh004"
            castShadow
            receiveShadow
            geometry={nodes.Mesh004.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh004_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh004_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="GeoSphere050008"
          position={[-11.478, 0.263, -8.095]}
          rotation={[Math.PI / 2, 0, -0.072]}
          scale={0}
        >
          <mesh
            name="Mesh006"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh006_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="GeoSphere050009"
          position={[-10.296, 0.263, -8.049]}
          rotation={[Math.PI / 2, 0, Math.PI / 4]}
          scale={0}
        >
          <mesh
            name="Mesh006"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh006_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh006_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Sphere012005"
          position={[-15.122, 0.474, -17.582]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.004}
        >
          <mesh
            name="Mesh002"
            castShadow
            receiveShadow
            geometry={nodes.Mesh002.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh002_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh002_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Hedra027004"
          position={[-17.713, 0.297, -17.992]}
          rotation={[-Math.PI / 2, 0, 1.037]}
          scale={0}
        >
          <mesh
            name="Mesh007"
            castShadow
            receiveShadow
            geometry={nodes.Mesh007.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh007_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh007_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="GeoSphere050010"
          position={[-17.517, 0.263, -18.083]}
          rotation={[Math.PI / 2, 0, -0.072]}
          scale={0}
        >
          <mesh
            name="Mesh008"
            castShadow
            receiveShadow
            geometry={nodes.Mesh008.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh008_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh008_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Hedra027005"
          position={[-14.33, 0.297, -17.876]}
          rotation={[-Math.PI / 2, 0, 1.037]}
          scale={0}
        >
          <mesh
            name="Mesh009"
            castShadow
            receiveShadow
            geometry={nodes.Mesh009.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh009_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh009_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Sphere012007"
          position={[-11.314, 0.474, -17.582]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.004}
        >
          <mesh
            name="Mesh010"
            castShadow
            receiveShadow
            geometry={nodes.Mesh010.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh010_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh010_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Hedra027007"
          position={[-10.522, 0.297, -17.876]}
          rotation={[-Math.PI / 2, 0, 1.037]}
          scale={0}
        >
          <mesh
            name="Mesh011"
            castShadow
            receiveShadow
            geometry={nodes.Mesh011.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh011_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh011_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="Sphere012018"
          position={[-8.427, 0.324, -14.855]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.003}
        >
          <mesh
            name="Mesh012"
            castShadow
            receiveShadow
            geometry={nodes.Mesh012.geometry}
            material={materials["01 - Default"]}
          />
          <mesh
            name="Mesh012_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh012_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <group
          name="GeoSphere050011"
          position={[-12.866, 0.263, -17.641]}
          rotation={[Math.PI / 2, 0, -0.072]}
          scale={0}
        >
          <mesh
            name="Mesh013"
            castShadow
            receiveShadow
            geometry={nodes.Mesh013.geometry}
            material={materials["03 - Default"]}
          />
          <mesh
            name="Mesh013_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh013_1.geometry}
            material={materials["04 - Default"]}
          />
        </group>
        <mesh
          name="floodlight004"
          castShadow
          receiveShadow
          geometry={nodes.floodlight004.geometry}
          material={materials.Mat}
          position={[-16.273, 1.183, -11.286]}
          rotation={[2.622, 0.807, 0.392]}
          scale={[-0.185, -0.153, -0.008]}
        />
        <mesh
          name="floodlight005"
          castShadow
          receiveShadow
          geometry={nodes.floodlight005.geometry}
          material={materials.Mat}
          position={[-9.933, 1.183, -11.337]}
          rotation={[2.638, -0.779, -0.37]}
          scale={[-0.185, -0.153, -0.008]}
        />
        <mesh
          name="floodlight008"
          castShadow
          receiveShadow
          geometry={nodes.floodlight008.geometry}
          material={materials.Mat}
          position={[-9.933, 1.183, -13.816]}
          rotation={[1.54, -1.22, -1.604]}
          scale={[-0.185, -0.153, -0.008]}
        />
        <mesh
          name="floodlight009"
          castShadow
          receiveShadow
          geometry={nodes.floodlight009.geometry}
          material={materials.Mat}
          position={[-16.273, 1.183, -13.857]}
          rotation={[1.554, 1.22, 1.588]}
          scale={[-0.185, -0.153, -0.008]}
        />
        <group
          name="flag003"
          position={[-14.557, 0.398, -12.853]}
          rotation={[0, -0.51, 0]}
          scale={[0.246, 8.346, 0.246]}
        >
          <mesh
            name="Cylinder026"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder026_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026_1.geometry}
            material={materials.flag}
          />
        </group>
        <group
          name="flag006"
          position={[-13.54, 0.398, -11.28]}
          rotation={[0, 0.505, 0]}
          scale={[0.246, 8.346, 0.246]}
        >
          <mesh
            name="Cylinder026"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder026_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026_1.geometry}
            material={materials.flag}
          />
        </group>
        <group
          name="flag004"
          position={[-12.617, 0.398, -11.28]}
          rotation={[0, 0.505, 0]}
          scale={[0.246, 8.346, 0.246]}
        >
          <mesh
            name="Cylinder026"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder026_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026_1.geometry}
            material={materials.flag}
          />
        </group>
        <group
          name="flag002"
          position={[-11.619, 0.398, -12.862]}
          rotation={[0, -0.172, 0]}
          scale={[0.246, 8.346, 0.246]}
        >
          <mesh
            name="Cylinder026"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder026_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026_1.geometry}
            material={materials.flag}
          />
        </group>
        <group
          name="flag001"
          position={[-10.837, 0.398, -11.035]}
          rotation={[0, 0.269, 0]}
          scale={[0.246, 8.346, 0.246]}
        >
          <mesh
            name="Cylinder026"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder026_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026_1.geometry}
            material={materials.flag}
          />
        </group>
        <group
          name="flag005"
          position={[-15.141, 0.398, -11.063]}
          rotation={[0, 0.269, 0]}
          scale={[0.246, 8.346, 0.246]}
        >
          <mesh
            name="Cylinder026"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder026_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder026_1.geometry}
            material={materials.flag}
          />
        </group>
        <mesh
          name="Cube009"
          castShadow
          receiveShadow
          geometry={nodes.Cube009.geometry}
          material={materials.Mat}
          position={[-12.62, 0.022, -11.736]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube010"
          castShadow
          receiveShadow
          geometry={nodes.Cube010.geometry}
          material={materials.Mat}
          position={[-13.539, 0.022, -11.736]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube011"
          castShadow
          receiveShadow
          geometry={nodes.Cube011.geometry}
          material={materials.Mat}
          position={[-11.075, 0.022, -15.816]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube012"
          castShadow
          receiveShadow
          geometry={nodes.Cube012.geometry}
          material={materials.Mat}
          position={[-10.872, 0.022, -15.212]}
          rotation={[0, 0.726, 0]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube014"
          castShadow
          receiveShadow
          geometry={nodes.Cube014.geometry}
          material={materials.Mat}
          position={[-9.7, 0.022, -11.588]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube015"
          castShadow
          receiveShadow
          geometry={nodes.Cube015.geometry}
          material={materials.Mat}
          position={[-16.557, 0.022, -11.576]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube017"
          castShadow
          receiveShadow
          geometry={nodes.Cube017.geometry}
          material={materials.Mat}
          position={[-11.075, 0.022, -16.543]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube018"
          castShadow
          receiveShadow
          geometry={nodes.Cube018.geometry}
          material={materials.Mat}
          position={[-9.7, 0.022, -15.102]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube019"
          castShadow
          receiveShadow
          geometry={nodes.Cube019.geometry}
          material={materials.Mat}
          position={[-15.952, 0.022, -11.323]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube020"
          castShadow
          receiveShadow
          geometry={nodes.Cube020.geometry}
          material={materials.Mat}
          position={[-11.537, 0.022, -11.586]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube021"
          castShadow
          receiveShadow
          geometry={nodes.Cube021.geometry}
          material={materials.Mat}
          position={[-14.554, 0.022, -11.586]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube022"
          castShadow
          receiveShadow
          geometry={nodes.Cube022.geometry}
          material={materials.Mat}
          position={[-10.243, 0.022, -11.284]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube024"
          castShadow
          receiveShadow
          geometry={nodes.Cube024.geometry}
          material={materials.Mat}
          position={[-16.557, 0.022, -14.904]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube025"
          castShadow
          receiveShadow
          geometry={nodes.Cube025.geometry}
          material={materials.Mat}
          position={[-16.087, 0.022, -17.363]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube026"
          castShadow
          receiveShadow
          geometry={nodes.Cube026.geometry}
          material={materials.Mat}
          position={[-9.965, 0.022, -13.969]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube027"
          castShadow
          receiveShadow
          geometry={nodes.Cube027.geometry}
          material={materials.Mat}
          position={[-9.975, 0.022, -14.638]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube028"
          castShadow
          receiveShadow
          geometry={nodes.Cube028.geometry}
          material={materials.Mat}
          position={[-16.132, 0.022, -13.969]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube029"
          castShadow
          receiveShadow
          geometry={nodes.Cube029.geometry}
          material={materials.Mat}
          position={[-16.134, 0.022, -14.48]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube030"
          castShadow
          receiveShadow
          geometry={nodes.Cube030.geometry}
          material={materials.Mat}
          position={[-13.543, 0.022, -10.823]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube031"
          castShadow
          receiveShadow
          geometry={nodes.Cube031.geometry}
          material={materials.Mat}
          position={[-13.538, 0.022, -9.856]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube032"
          castShadow
          receiveShadow
          geometry={nodes.Cube032.geometry}
          material={materials.Mat}
          position={[-12.62, 0.022, -9.856]}
          scale={[1, 2.156, 1]}
        />
        <mesh
          name="Cube033"
          castShadow
          receiveShadow
          geometry={nodes.Cube033.geometry}
          material={materials.Mat}
          position={[-12.615, 0.022, -10.823]}
          scale={[1, 2.156, 1]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/zone3.glb");
