/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Model5(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/zone5.glb");
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="base_zone_5003"
          position={[-26.405, -1.272, -26.235]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[5.649, 1.271, 5.649]}
        >
          <mesh
            name="Cube139"
            castShadow
            receiveShadow
            geometry={nodes.Cube139.geometry}
            material={materials.road}
          />
          <mesh
            name="Cube139_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube139_1.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cube139_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube139_2.geometry}
            material={materials["dark-purple-gradient1"]}
          />
        </group>
        <mesh
          name="zone-5-road"
          castShadow
          receiveShadow
          geometry={nodes["zone-5-road"].geometry}
          material={materials.Mat}
          position={[-21.051, 0, -29.362]}
          scale={0.233}
        />
        <mesh
          name="Cube036"
          castShadow
          receiveShadow
          geometry={nodes.Cube036.geometry}
          material={materials.Mat}
          position={[2.86, 0, 0.053]}
        />
        <mesh
          name="Cube037"
          castShadow
          receiveShadow
          geometry={nodes.Cube037.geometry}
          material={materials.Mat}
          position={[-2.045, 0, 0.08]}
        />
        <mesh
          name="Cube038"
          castShadow
          receiveShadow
          geometry={nodes.Cube038.geometry}
          material={materials.Mat}
          position={[-0.494, 0, 0.067]}
        />
        <mesh
          name="Cube039"
          castShadow
          receiveShadow
          geometry={nodes.Cube039.geometry}
          material={materials.Mat}
          position={[1.136, 0, 0.067]}
        />
        <mesh
          name="Cube040"
          castShadow
          receiveShadow
          geometry={nodes.Cube040.geometry}
          material={materials.Mat}
          position={[2.847, 0, -2.553]}
        />
        <mesh
          name="Cube045"
          castShadow
          receiveShadow
          geometry={nodes.Cube045.geometry}
          material={materials.Mat}
          position={[-2.045, 0, 0.08]}
        />
        <mesh
          name="Cube046"
          castShadow
          receiveShadow
          geometry={nodes.Cube046.geometry}
          material={materials.Mat}
          position={[-28.022, 0.234, -29.346]}
        />
        <group
          name="currys_store_zone_4003"
          position={[-23.128, 1.09, -26.16]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.163}
        >
          <mesh
            name="currys_store_zone_1_1006"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1006.geometry}
            material={materials.Mat}
          />
          <mesh
            name="currys_store_zone_1_1006_1"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1006_1.geometry}
            material={materials["store logo-new"]}
          />
          <mesh
            name="currys_store_zone_1_1006_2"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1006_2.geometry}
            material={materials["glass-store-grey"]}
          />
        </group>
        <mesh
          name="building1001"
          castShadow
          receiveShadow
          geometry={nodes.building1001.geometry}
          material={materials.Mat}
          position={[-26.784, 0.145, -23.097]}
          rotation={[0, -1.563, 0]}
          scale={[0.454, 1.34, 0.454]}
        />
        <mesh
          name="window-plane1001"
          castShadow
          receiveShadow
          geometry={nodes["window-plane1001"].geometry}
          material={materials["glass-gradient"]}
          position={[-26.838, 1.603, -22.604]}
          rotation={[0, -1.563, -Math.PI / 2]}
          scale={[1.401, 0.716, 0.4]}
        />
        <mesh
          name="window-plane2001"
          castShadow
          receiveShadow
          geometry={nodes["window-plane2001"].geometry}
          material={materials["glass-gradient"]}
          position={[-26.413, 1.603, -23.033]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1.401, 0.716, 0.4]}
        />
        <mesh
          name="building1002"
          castShadow
          receiveShadow
          geometry={nodes.building1002.geometry}
          material={materials.Mat}
          position={[-26.822, -0.9, -28.415]}
          rotation={[0, -1.563, 0]}
          scale={[0.47, 1.387, 0.47]}
        />
        <mesh
          name="window-plane1002"
          castShadow
          receiveShadow
          geometry={nodes["window-plane1002"].geometry}
          material={materials["glass-gradient"]}
          position={[-26.878, 0.63, -27.942]}
          rotation={[0, -1.563, -Math.PI / 2]}
          scale={[1.451, 0.741, 0.414]}
        />
        <mesh
          name="window-plane2002"
          castShadow
          receiveShadow
          geometry={nodes["window-plane2002"].geometry}
          material={materials["glass-gradient"]}
          position={[-26.454, 0.609, -28.349]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1.451, 0.741, 0.414]}
        />
        <mesh
          name="building1003"
          castShadow
          receiveShadow
          geometry={nodes.building1003.geometry}
          material={materials.Mat}
          position={[-24.867, 0.161, -26.967]}
          rotation={[0, -1.563, 0]}
          scale={[0.522, 1.41, 0.522]}
        />
        <mesh
          name="window-plane1003"
          castShadow
          receiveShadow
          geometry={nodes["window-plane1003"].geometry}
          material={materials["glass-gradient"]}
          position={[-24.93, 1.689, -26.4]}
          rotation={[0, -1.563, -1.571]}
          scale={[1.474, 0.823, 0.46]}
        />
        <mesh
          name="window-plane2003"
          castShadow
          receiveShadow
          geometry={nodes["window-plane2003"].geometry}
          material={materials["glass-gradient"]}
          position={[-24.46, 1.694, -26.893]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1.474, 0.823, 0.46]}
        />
        <mesh
          name="techtronica-sign"
          castShadow
          receiveShadow
          geometry={nodes["techtronica-sign"].geometry}
          material={materials["techtronica-sign"]}
          position={[-24.923, 3.925, -26.888]}
        />
        <mesh
          name="building2-main"
          castShadow
          receiveShadow
          geometry={nodes["building2-main"].geometry}
          material={materials.Mat}
          position={[-23.542, 1.899, -23.566]}
          scale={0.497}
        />
        <group
          name="window-box"
          position={[-23.542, 1.46, -23.567]}
          scale={0.739}
        >
          <mesh
            name="Cube144"
            castShadow
            receiveShadow
            geometry={nodes.Cube144.geometry}
            material={materials["glass-gradient.001"]}
          />
          <mesh
            name="Cube144_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube144_1.geometry}
            material={materials.Mat}
          />
        </group>
        <group
          name="building3-main"
          position={[-30.76, 0.432, -26.321]}
          scale={0.928}
        >
          <mesh
            name="Cube150"
            castShadow
            receiveShadow
            geometry={nodes.Cube150.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cube150_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube150_1.geometry}
            material={materials["gradient-orange-purple"]}
          />
        </group>
        <group
          name="building3-main001"
          position={[-23.157, 0.399, -27.699]}
          scale={0.92}
        >
          <mesh
            name="Cube150"
            castShadow
            receiveShadow
            geometry={nodes.Cube150.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cube150_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube150_1.geometry}
            material={materials["gradient-orange-purple"]}
          />
        </group>
        <group
          name="building3-main002"
          position={[-25.879, 0.432, -24.041]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.92}
        >
          <mesh
            name="Cube150"
            castShadow
            receiveShadow
            geometry={nodes.Cube150.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cube150_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube150_1.geometry}
            material={materials["gradient-orange-purple"]}
          />
        </group>
        <group name="Cube041" position={[-30.761, 0.413, -27.951]}>
          <mesh
            name="Cube155"
            castShadow
            receiveShadow
            geometry={nodes.Cube155.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cube155_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube155_1.geometry}
            material={materials["glass-gradient.003"]}
          />
        </group>
        <group
          name="Cube042"
          position={[-22.698, 0.428, -30.351]}
          rotation={[Math.PI, -Math.PI / 2, 0]}
          scale={-1}
        >
          <mesh
            name="Cube157"
            castShadow
            receiveShadow
            geometry={nodes.Cube157.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cube157_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube157_1.geometry}
            material={materials["glass-gradient.002"]}
          />
        </group>
        <mesh
          name="building1004"
          castShadow
          receiveShadow
          geometry={nodes.building1004.geometry}
          material={materials.Mat}
          position={[-24.478, 0.167, -30.472]}
          rotation={[0, -1.563, 0]}
          scale={[0.457, 1.349, 0.457]}
        />
        <mesh
          name="window-plane1004"
          castShadow
          receiveShadow
          geometry={nodes["window-plane1004"].geometry}
          material={materials["gradient-orange-purple"]}
          position={[-25.459, 1.634, -29.988]}
          rotation={[0, -1.563, -Math.PI / 2]}
          scale={[1.411, 0.721, 1.291]}
        />
        <mesh
          name="window-plane2004"
          castShadow
          receiveShadow
          geometry={nodes["window-plane2004"].geometry}
          material={materials["gradient-orange-purple"]}
          position={[-24.116, 1.634, -30.451]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1.411, 0.721, 0.403]}
        />
        <mesh
          name="building1005"
          castShadow
          receiveShadow
          geometry={nodes.building1005.geometry}
          material={materials.Mat}
          position={[-25.378, 0.167, -30.465]}
          rotation={[0, -1.563, 0]}
          scale={[0.457, 1.349, 0.457]}
        />
        <mesh
          name="building1006"
          castShadow
          receiveShadow
          geometry={nodes.building1006.geometry}
          material={materials.Mat}
          position={[-26.278, 0.167, -30.457]}
          rotation={[0, -1.563, 0]}
          scale={[0.457, 1.349, 0.457]}
        />
        <mesh
          name="pad-square"
          castShadow
          receiveShadow
          geometry={nodes["pad-square"].geometry}
          material={materials.Mat}
          position={[-30.448, 0, -30.333]}
          scale={[7.22, 0.453, 7.22]}
        />
        <mesh
          name="Cylinder-pad"
          castShadow
          receiveShadow
          geometry={nodes["Cylinder-pad"].geometry}
          material={materials.Mat}
          position={[-30.448, 0.118, -30.33]}
          scale={[1.405, 0.976, 1.405]}
        />
        <mesh
          name="rocket"
          castShadow
          receiveShadow
          geometry={nodes.rocket.geometry}
          material={materials.Mat}
          position={[-30.442, 1.517, -30.318]}
          scale={[0.431, 0.523, 0.431]}
        />
        <mesh
          name="booster1"
          castShadow
          receiveShadow
          geometry={nodes.booster1.geometry}
          material={materials.Mat}
          position={[-30.342, 0.636, -30.234]}
          scale={0.864}
        />
        <mesh
          name="booster2"
          castShadow
          receiveShadow
          geometry={nodes.booster2.geometry}
          material={materials.Mat}
          position={[-30.572, 0.636, -30.274]}
          scale={0.864}
        />
        <mesh
          name="booster3"
          castShadow
          receiveShadow
          geometry={nodes.booster3.geometry}
          material={materials.Mat}
          position={[-30.421, 0.636, -30.454]}
          scale={0.864}
        />
        <mesh
          name="rocket-fins"
          castShadow
          receiveShadow
          geometry={nodes["rocket-fins"].geometry}
          material={materials.Mat}
          position={[-30.433, 2.374, -30.323]}
          scale={0.864}
        />
        <mesh
          name="rocket-window"
          castShadow
          receiveShadow
          geometry={nodes["rocket-window"].geometry}
          material={materials["Mat-shine"]}
          position={[-30.24, 1.593, -30.016]}
          scale={[0.864, 0.986, 0.864]}
        />
        <mesh
          name="tower-mesh1-complete002"
          castShadow
          receiveShadow
          geometry={nodes["tower-mesh1-complete002"].geometry}
          material={materials.Mat}
          position={[-31.356, 1.613, -29.414]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1.478, 0.994, 1.478]}
        />
        <mesh
          name="tower-mesh1-complete001"
          castShadow
          receiveShadow
          geometry={nodes["tower-mesh1-complete001"].geometry}
          material={materials.Mat}
          position={[-31.356, 1.613, -31.238]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1.478, 0.994, 1.478]}
        />
        <mesh
          name="tower-mesh1-complete003"
          castShadow
          receiveShadow
          geometry={nodes["tower-mesh1-complete003"].geometry}
          material={materials.Mat}
          position={[-29.55, 1.613, -31.238]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1.478, 0.994, 1.478]}
        />
        <mesh
          name="zone5-marker-store"
          castShadow
          receiveShadow
          geometry={nodes["zone5-marker-store"].geometry}
          material={materials["marker-store-user"]}
          position={[-23.085, 2.349, -26.185]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone5-marker-school"
          castShadow
          receiveShadow
          geometry={nodes["zone5-marker-school"].geometry}
          material={materials["marker-university"]}
          position={[-30.412, 2.66, -24.19]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone5-marker-info"
          castShadow
          receiveShadow
          geometry={nodes["zone5-marker-info"].geometry}
          material={materials["marker-info"]}
          position={[-29.475, 2.208, -26.878]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone5-marker-activity1"
          castShadow
          receiveShadow
          geometry={nodes["zone5-marker-activity1"].geometry}
          material={materials["marker-game-play.002"]}
          position={[-22.66, 1.555, -30.478]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone5-marker-teleport"
          castShadow
          receiveShadow
          geometry={nodes["zone5-marker-teleport"].geometry}
          material={materials["marker-5-teleport"]}
          position={[-24.716, 2.724, -22.845]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone5-marker-rocket"
          castShadow
          receiveShadow
          geometry={nodes["zone5-marker-rocket"].geometry}
          material={materials["marker-game-play.003"]}
          position={[-30.616, 2.893, -30.478]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <group
          name="school-placeholder"
          position={[-30.765, 0.642, -24.444]}
          scale={[0.46, 0.662, 1]}
        >
          <mesh
            name="Cube156"
            castShadow
            receiveShadow
            geometry={nodes.Cube156.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cube156_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube156_1.geometry}
            material={materials["glass-gradient.002"]}
          />
        </group>
        <mesh
          name="arcade-sign"
          castShadow
          receiveShadow
          geometry={nodes["arcade-sign"].geometry}
          material={materials["arcade-sign"]}
          position={[-23.005, 0.673, -29.747]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.732}
        />
        <mesh
          name="spaceacademy-sign_A"
          castShadow
          receiveShadow
          geometry={nodes["spaceacademy-sign_A"].geometry}
          material={materials["spaceacademy-sign_A"]}
          position={[-30.29, 1.126, -24.445]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.486}
        />
        <group
          name="teleport-blue"
          position={[-25.704, 1.179, -23.055]}
          scale={[0.808, 0.706, 0.808]}
        >
          <mesh
            name="Cylinder042"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder042.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder042_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder042_1.geometry}
            material={materials["gradient-orange-purple"]}
          />
        </group>
        <group
          name="teleport-floor-left"
          position={[-25.702, 0.98, -23.06]}
          scale={[0.621, 0.818, 0.642]}
        >
          <mesh
            name="Cylinder044"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder044.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder044_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder044_1.geometry}
            material={materials["gradient-orange-purple"]}
          />
        </group>
        <group
          name="teleport-floor-right"
          position={[-24.631, 0.98, -23.06]}
          scale={[0.621, 0.818, 0.642]}
        >
          <mesh
            name="Cylinder044"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder044.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder044_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder044_1.geometry}
            material={materials["gradient-orange-purple"]}
          />
        </group>
        <mesh
          name="teleport-hub"
          castShadow
          receiveShadow
          geometry={nodes["teleport-hub"].geometry}
          material={materials.Mat}
          position={[-25.162, 0.708, -23.005]}
          scale={[1, 0.663, 0.476]}
        />
        <mesh
          name="teleport-sign"
          castShadow
          receiveShadow
          geometry={nodes["teleport-sign"].geometry}
          material={materials["teleport-graphic-1"]}
          position={[-25.679, 1.384, -23.463]}
        />
        <group name="teleport-sign001" position={[-25.162, 1.726, -23.438]}>
          <mesh
            name="Plane216"
            castShadow
            receiveShadow
            geometry={nodes.Plane216.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Plane216_1"
            castShadow
            receiveShadow
            geometry={nodes.Plane216_1.geometry}
            material={materials["teleport-sign-top"]}
          />
        </group>
        <group
          name="teleport-pink"
          position={[-24.63, 1.179, -23.055]}
          scale={[0.808, 0.706, 0.808]}
        >
          <mesh
            name="Cylinder049"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder049.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cylinder049_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder049_1.geometry}
            material={materials["gradient-orange-purple"]}
          />
        </group>
        <mesh
          name="teleport-sign002"
          castShadow
          receiveShadow
          geometry={nodes["teleport-sign002"].geometry}
          material={materials["teleport-graphic-2"]}
          position={[-24.645, 1.387, -23.463]}
        />
        <mesh
          name="building1"
          castShadow
          receiveShadow
          geometry={nodes.building1.geometry}
          material={materials.Mat}
          position={[-30.689, 0.167, -22.224]}
          rotation={[0, -1.563, 0]}
          scale={[0.457, 1.349, 0.457]}
        />
        <mesh
          name="window-plane1"
          castShadow
          receiveShadow
          geometry={nodes["window-plane1"].geometry}
          material={materials["glass-gradient"]}
          position={[-30.744, 1.634, -21.728]}
          rotation={[0, -1.563, -Math.PI / 2]}
          scale={[1.411, 0.721, 0.403]}
        />
        <mesh
          name="window-plane2"
          castShadow
          receiveShadow
          geometry={nodes["window-plane2"].geometry}
          material={materials["glass-gradient"]}
          position={[-30.332, 1.634, -22.159]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1.411, 0.721, 0.403]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/zone5.glb");
