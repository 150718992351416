/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/


import React, { useRef, useMemo, useContext, createContext } from "react";
import { useGLTF, Merged, useAnimations } from "@react-three/drei";

const context = createContext();
export function Instances({ children, ...props }) {
  const { nodes } = useGLTF("/zone2.glb");
  const instances = useMemo(
    () => ({
      Beachtilex: nodes.beach_tile_1x1_003001,
      Beachtilex1: nodes.beach_tile_1x1_004001,
      Beachtilex2: nodes.beach_tile_1x1_006001,
      Car: nodes.car007,
      Businesscenter: nodes.business_center_001001,
      Businesscenter4: nodes.business_center_004005,
      Bowlingpin: nodes.Bowling_pin_2,
      Beachtilex3: nodes.beach_tile_1x1_004004,
      Bunting: nodes.bunting1,
      Cylinder: nodes.Cylinder,
      Cube5: nodes.Cube013,
      Cube6: nodes.Cube016,
    }),
    [nodes]
  );
  return (
    <Merged meshes={instances} {...props}>
      {(instances) => (
        <context.Provider value={instances} children={children} />
      )}
    </Merged>
  );
}

export function Zone2(props) {
  const instances = useContext(context);
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/zone2.glb");
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="base_zone_2"
          position={[-0.003, -1.272, -13.063]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[5.649, 1.271, 5.649]}
        >
          <mesh
            name="Cube007"
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials.road}
          />
          <mesh
            name="Cube007_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube007_1.geometry}
            material={materials.Mat}
          />
        </group>
        <mesh
          name="zone2-road"
          castShadow
          receiveShadow
          geometry={nodes["zone2-road"].geometry}
          material={materials.Mat}
          position={[-3.639, 0.007, -7.632]}
          scale={[0.978, 1, 0.978]}
        />
        <mesh
          name="ocean"
          castShadow
          receiveShadow
          geometry={nodes.ocean.geometry}
          material={materials.water}
        />
        <instances.Beachtilex
          name="beach_tile_1x1_003001"
          position={[4.298, 0.009, -10.238]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex
          name="beach_tile_1x1_003002"
          position={[4.298, 0.009, -12.486]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex
          name="beach_tile_1x1_003003"
          position={[4.298, 0.009, -14.742]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex1
          name="beach_tile_1x1_004001"
          position={[4.295, 0.009, -11.366]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex1
          name="beach_tile_1x1_004002"
          position={[4.295, 0.009, -7.984]}
          rotation={[Math.PI / 2, -0.002, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex1
          name="beach_tile_1x1_004003"
          position={[4.295, 0.009, -17.002]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex2
          name="beach_tile_1x1_006001"
          position={[4.296, 0.009, -9.111]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex2
          name="beach_tile_1x1_006002"
          position={[4.3, 0.009, -13.614]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex2
          name="beach_tile_1x1_006003"
          position={[4.3, 0.009, -15.87]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex2
          name="beach_tile_1x1_006004"
          position={[4.296, 0.009, -18.129]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <mesh
          name="cafe_008001"
          castShadow
          receiveShadow
          geometry={nodes.cafe_008001.geometry}
          material={materials["Material.004"]}
          position={[-2.937, 0.002, -13.574]}
          rotation={[Math.PI / 2, 0, -1.567]}
          scale={0.1}
        />
        <mesh
          name="supermarket_002001"
          castShadow
          receiveShadow
          geometry={nodes.supermarket_002001.geometry}
          material={materials["Material.004"]}
          position={[-2.947, 0.005, -14.198]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.1}
        />
        <mesh
          name="zone2-marker-store"
          castShadow
          receiveShadow
          geometry={nodes["zone2-marker-store"].geometry}
          material={materials["marker-store-user"]}
          position={[-0.729, 1.275, -13.239]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone2-marker-school"
          castShadow
          receiveShadow
          geometry={nodes["zone2-marker-school"].geometry}
          material={materials["marker-university"]}
          position={[1.749, 1.248, -17.223]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <group
          name="billboard002"
          position={[-4.364, 1.366, -8.525]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.108}
        >
          <mesh
            name="billboard_1002"
            castShadow
            receiveShadow
            geometry={nodes.billboard_1002.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            name="billboard_1002_1"
            castShadow
            receiveShadow
            geometry={nodes.billboard_1002_1.geometry}
            material={materials.Mat}
          />
          <mesh
            name="billboard_1002_2"
            castShadow
            receiveShadow
            geometry={nodes.billboard_1002_2.geometry}
            material={materials["billboard-3"]}
          />
        </group>
        <mesh
          name="Plane007"
          castShadow
          receiveShadow
          geometry={nodes.Plane007.geometry}
          material={materials.Mat}
          position={[3.686, 0.006, -8.854]}
        />
        <mesh
          name="zone2-marker-bowling"
          castShadow
          receiveShadow
          geometry={nodes["zone2-marker-bowling"].geometry}
          material={materials["marker-game-play.001"]}
          position={[-2.665, 1.315, -9.983]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone2-marker-bank"
          castShadow
          receiveShadow
          geometry={nodes["zone2-marker-bank"].geometry}
          material={materials["marker-chance"]}
          position={[3.973, 0.479, -11.845]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone2-marker-pinata"
          castShadow
          receiveShadow
          geometry={nodes["zone2-marker-pinata"].geometry}
          material={materials["marker-game-play"]}
          position={[-2.162, 0.803, -12.86]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone2-marker-exit"
          castShadow
          receiveShadow
          geometry={nodes["zone2-marker-exit"].geometry}
          material={materials["marker-zone1-exit"]}
          position={[-5.145, 0.523, -15.869]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="zone2-marker-info"
          castShadow
          receiveShadow
          geometry={nodes["zone2-marker-info"].geometry}
          material={materials["marker-info"]}
          position={[2.303, 0.596, -9.657]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.119, 0.143, 0.119]}
        />
        <mesh
          name="road_tile_bridge_2x2_007004"
          castShadow
          receiveShadow
          geometry={nodes.road_tile_bridge_2x2_007004.geometry}
          material={materials.Material}
          position={[-6.583, 0.025, -15.867]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.119}
        />
        <instances.Car
          name="car007"
          position={[-1.124, 0.008, -12.423]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <mesh
          name="cafe_006004"
          castShadow
          receiveShadow
          geometry={nodes.cafe_006004.geometry}
          material={materials["Material.004"]}
          position={[-2.965, 0.003, -12.094]}
          rotation={[Math.PI / 2, 0, -1.567]}
          scale={0.1}
        />
        <mesh
          name="services_store_009001"
          castShadow
          receiveShadow
          geometry={nodes.services_store_009001.geometry}
          material={materials["Material.004"]}
          position={[-2.96, 0.002, -12.862]}
          rotation={[Math.PI / 2, 0, -1.567]}
          scale={0.1}
        />
        <mesh
          name="transport_water_007003"
          castShadow
          receiveShadow
          geometry={nodes.transport_water_007003.geometry}
          material={materials["Material.004"]}
          position={[5.328, -0.179, -8.898]}
          rotation={[Math.PI / 2, 0, -2.44]}
          scale={0.09}
        />
        <mesh
          name="transport_water_010002"
          castShadow
          receiveShadow
          geometry={nodes.transport_water_010002.geometry}
          material={materials["Material.004"]}
          position={[5.3, -0.161, -16.85]}
          rotation={[Math.PI / 2, 0, 0.403]}
          scale={0.09}
        />
        <instances.Businesscenter
          name="business_center_001001"
          position={[-1.085, -0.011, -10.142]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.057}
        />
        <mesh
          name="business_center_005001"
          castShadow
          receiveShadow
          geometry={nodes.business_center_005001.geometry}
          material={materials["Material.004"]}
          position={[-4.023, -0.042, -17.22]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.075}
        />
        <mesh
          name="business_center_009001"
          castShadow
          receiveShadow
          geometry={nodes.business_center_009001.geometry}
          material={materials["Material.004"]}
          position={[-2.261, 0.023, -17.27]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.075}
        />
        <mesh
          name="business_center_005003"
          castShadow
          receiveShadow
          geometry={nodes.business_center_005003.geometry}
          material={materials["Material.004"]}
          position={[-4.512, -0.042, -11.902]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.082}
        />
        <instances.Businesscenter4
          name="business_center_004005"
          position={[0.949, -0.014, -10.143]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.057}
        />
        <instances.Businesscenter4
          name="business_center_004006"
          position={[1.84, -0.014, -10.999]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.049}
        />
        <instances.Businesscenter
          name="business_center_001002"
          position={[1.795, -0.011, -13.987]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.057}
        />
        <instances.Bowlingpin
          name="Bowling_pin_2"
          position={[-3.804, 0.527, -10.162]}
          rotation={[-1.471, 1.042, 1.695]}
          scale={[0.209, 0.228, 0.209]}
        />
        <instances.Bowlingpin
          name="bowling_pin_1"
          position={[-3.58, 0.527, -10.213]}
          rotation={[-1.139, 1.314, 1.049]}
          scale={[0.209, 0.228, 0.209]}
        />
        <mesh
          name="bowling-alley"
          castShadow
          receiveShadow
          geometry={nodes["bowling-alley"].geometry}
          material={materials.Mat}
          position={[-3.314, 0.221, -10.335]}
          scale={[0.791, 0.325, 0.791]}
        />
        <mesh
          name="bowling_ball"
          castShadow
          receiveShadow
          geometry={nodes.bowling_ball.geometry}
          material={materials.Mat}
          position={[-3.4, 0.63, -9.954]}
          rotation={[0, 0.223, 0]}
          scale={0.193}
        />
        <mesh
          name="car008"
          castShadow
          receiveShadow
          geometry={nodes.car008.geometry}
          material={materials.Material}
          position={[-2.4, 0.008, -10.148]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.103}
        />
        <instances.Bowlingpin
          name="Bowling_pin_2001"
          position={[-3.643, 0.527, -9.958]}
          rotation={[-1.452, 1.083, 1.777]}
          scale={[0.209, 0.228, 0.209]}
        />
        <mesh
          name="surf_school"
          castShadow
          receiveShadow
          geometry={nodes.surf_school.geometry}
          material={materials.Mat}
          position={[1.637, -0.028, -17.476]}
        />
        <instances.Beachtilex3
          name="beach_tile_1x1_004004"
          position={[2.851, 0.159, -18.033]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex3
          name="beach_tile_1x1_004005"
          position={[2.866, 0.159, -16.763]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.141}
        />
        <instances.Beachtilex3
          name="beach_tile_1x1_004007"
          position={[2.154, 0.169, -16.268]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.141}
        />
        <mesh
          name="Cube004"
          castShadow
          receiveShadow
          geometry={nodes.Cube004.geometry}
          material={materials.Mat}
          position={[2.409, 0.226, -9.542]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.454}
        />
        <instances.Car
          name="car006"
          position={[-1.124, 0.008, -16.175]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.103}
        />
        <instances.Car
          name="car009"
          position={[-0.571, 0.008, -15.622]}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={0.103}
        />
        <instances.Bunting
          name="bunting1"
          position={[-2.27, 0.319, -12.9]}
          rotation={[0.052, 0.696, -0.068]}
          scale={[0.776, 0.541, 0.531]}
        />
        <instances.Bunting
          name="bunting1001"
          position={[-2.258, 0.322, -11.991]}
          rotation={[0.057, -0.705, -0.006]}
          scale={[0.767, 0.433, 0.531]}
        />
        <instances.Bunting
          name="bunting1002"
          position={[-2.307, 0.308, -14.533]}
          rotation={[0.04, 0.633, -0.076]}
          scale={[0.663, 0.463, 0.454]}
        />
        <instances.Bunting
          name="bunting1003"
          position={[-2.267, 0.306, -13.807]}
          rotation={[0.056, -0.786, -0.007]}
          scale={[0.839, 0.522, 0.512]}
        />
        <instances.Cylinder
          name="Cylinder"
          position={[-1.776, 0.183, -11.589]}
          scale={[1, 0.746, 1]}
        />
        <instances.Cylinder
          name="Cylinder003"
          position={[-1.778, 0.221, -13.323]}
          scale={[1, 0.746, 1]}
        />
        <mesh
          name="pinata"
          castShadow
          receiveShadow
          geometry={nodes.pinata.geometry}
          material={materials.Mat}
          position={[-2.161, 0.141, -12.995]}
          rotation={[0, -0.644, 0]}
          scale={[0.031, 0.021, 0.053]}
        />
        <instances.Cylinder
          name="Cylinder004"
          position={[-1.778, 0.221, -14.804]}
          scale={[1, 0.746, 1]}
        />
        <mesh
          name="pinata-stick"
          castShadow
          receiveShadow
          geometry={nodes["pinata-stick"].geometry}
          material={materials.Mat}
          position={[-2.139, 0.18, -12.881]}
          rotation={[-0.729, -0.427, -0.354]}
        />
        <group
          name="bowling-sign"
          position={[-2.696, 0.488, -10.363]}
          scale={0.318}
        >
          <mesh
            name="bowling-sign-ref"
            castShadow
            receiveShadow
            geometry={nodes["bowling-sign-ref"].geometry}
            material={materials.Mat}
          />
          <mesh
            name="bowling-sign-ref_1"
            castShadow
            receiveShadow
            geometry={nodes["bowling-sign-ref_1"].geometry}
            material={materials["bowling-sign-graphic"]}
          />
        </group>
        <group
          name="currys_store_zone_1005"
          position={[-0.804, 0.008, -13.46]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.147}
        >
          <mesh
            name="currys_store_zone_1_1005"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1005.geometry}
            material={materials.Mat}
          />
          <mesh
            name="currys_store_zone_1_1005_1"
            castShadow
            receiveShadow
            geometry={nodes.currys_store_zone_1_1005_1.geometry}
            material={materials["store logo"]}
          />
        </group>
        <group
          name="Cube006"
          position={[2.179, 0.565, -10.133]}
          rotation={[0, 1.57, 0]}
          scale={0.037}
        >
          <mesh
            name="Cube131"
            castShadow
            receiveShadow
            geometry={nodes.Cube131.geometry}
            material={materials.Mat}
          />
          <mesh
            name="Cube131_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube131_1.geometry}
            material={materials.B1_Darker}
          />
        </group>
        <instances.Cube5
          name="Cube013"
          position={[2.202, 0.833, -14.934]}
          rotation={[0, 1.569, 0]}
          scale={0.037}
        />
        <instances.Cube6
          name="Cube016"
          position={[1.7, 0.446, -12.837]}
          rotation={[0, 1.57, 0]}
          scale={0.419}
        />
        <instances.Cube5
          name="Cube002"
          position={[2.162, 0.831, -11.867]}
          rotation={[0, 1.57, 0]}
          scale={0.037}
        />
        <instances.Cube6
          name="Cube003"
          position={[-0.075, 0.446, -10.242]}
          scale={0.43}
        />
        <mesh
          name="scene10003"
          castShadow
          receiveShadow
          geometry={nodes.scene10003.geometry}
          material={materials.Mat}
          position={[-2.224, 0.006, -13.665]}
          rotation={[0, 1.153, 0]}
          scale={0.21}
        />
        <instances.Businesscenter4
          name="building-replace"
          position={[-0.857, -0.014, -17.241]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.057}
        />
        <group
          name="palm_7003"
          position={[-24.71, 0.004, 11.737]}
          rotation={[3.141, -1.561, 3.141]}
          scale={[0.08, 0.056, 0.08]}
        >
          <mesh
            name="Cube117"
            castShadow
            receiveShadow
            geometry={nodes.Cube117.geometry}
            material={materials["Material.024"]}
          />
          <mesh
            name="Cube117_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube117_1.geometry}
            material={materials["Material.027"]}
          />
          <mesh
            name="Cube117_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube117_2.geometry}
            material={materials["Material.028"]}
          />
          <mesh
            name="Cube117_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube117_3.geometry}
            material={materials["Material.023"]}
          />
          <mesh
            name="Cube117_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube117_4.geometry}
            material={materials["Material.025"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/zone2.glb");
